<template>
  <ValidationProvider
    v-slot="{ errors }"
    rules="required"
    mode="passive"
  >
    <v-card
      class="justification-card rounded-lg d-flex align-center justify-center flex-column mx-4"
    >
      <v-avatar
        class="justification-avatar mt-8"
      >
        <v-img :src="nomination.nominated.photo_url" />
      </v-avatar>

      <v-card-title>
        {{ nomination.nominated.pretty_name }}
      </v-card-title>

      <v-textarea
        v-model="nomineeJustification"
        class="justification-area"
        solo
        flat
        no-resize
        counter
        :error-messages="errors[0]"
        :rules="rules"
        :background-color="backgroundColor(errors)"
        :placeholder="placeholder(errors)"
      />
    </v-card>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'Justification',
  props: {
    nomination: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      focused: false,
      nomineeJustification: '',
      rules: [(nomineeJustification) => nomineeJustification.length <= 240 || this.$t('components.justification.max_characters')],
    };
  },
  watch: {
    nomineeJustification() {
      this.$emit('updateJustification', {
        id: this.nomination.nominated.id,
        justification: this.nomineeJustification,
      });
    },
  },
  methods: {
    changeFocus() {
      this.focused = !this.focused;
    },
    backgroundColor(errors) {
      if (errors.length > 0) {
        return 'error';
      }
      return 'textInputPost';
    },
    placeholder(errors) {
      if (errors.length > 0) {
        return this.$t('components.justification.write_something');
      }
      return this.$t('components.justification.justification_area');
    },
  },
};
</script>

<style lang="scss" scoped>
.justification-card {
  width: 292px;
  height: 320px;
  box-shadow: 0px 8px 20px $shadow-viking !important;

  .justification-avatar {
    width: 56px;
    height: 56px;
  }

  .justification-area {
    width: 224px;
    height: 152px;

    font-family: 'NunitoRegular';
    font-size: 14px;
    text-align: left;
    letter-spacing: 0.25px;
  }
}
</style>
