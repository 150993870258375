<template>
  <v-container class="d-flex align-center flex-column container">
    <div class="text-center mb-15">
      <div class="nominees-text">
        <p>
          {{ $t('views.nominees.check_nominees') }}
        </p>
      </div>
      <div class="nominees-subtext">
        <p>
          {{ $t('views.nominees.confirm_nominees') }}
        </p>
      </div>
    </div>

    <div class="nominees-container">
      <div
        v-for="nomination in nominations"
        :key="nomination.nominated.id"
      >
        <justification
          :nomination="nomination"
          @updateJustification="doUpdate"
        />
      </div>
    </div>

    <div class="footer-btns">
      <indicate-button
        class="mr-6"
        type-btn="change"
      />
      <indicate-button
        type-btn="send"
        @doNominations="doNominations"
      />
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Justification from '../components/globals/Justification.vue';
import IndicateButton from '../components/globals/IndicateButton.vue';

export default {
  name: 'Nominees',
  components: {
    Justification,
    IndicateButton,
  },
  data() {
    return {
      nominations: [],
    };
  },
  computed: {
    ...mapGetters(['getSelectedNominations', 'currentUser', 'getValhallaId']),
  },
  created() {
    this.nominations = this.getSelectedNominations;
  },
  methods: {
    ...mapActions(['postNominations']),
    doNominations() {
      this.postNominations({
        valhalla_id: this.getValhallaId,
        nominations: this.nominations,
        user_id: this.currentUser.id,
      }).finally(() => this.$router.push({ name: 'valhalla', params: { created: true } }));
    },
    doUpdate(payload) {
      // eslint-disable-next-line max-len
      const index = this.nominations.findIndex(
        (nomination) => nomination.nominated.id === payload.id,
      );
      this.nominations[index].justification = payload.justification;
    },
  },
};
</script>

<style lang="scss">
.container {
  padding-bottom: 60px;
}
.nominees-text {
  height: 44px;
  font: 34px/36px MontserratRegular;
  color: $primary;
}
.nominees-subtext {
  font: 16px/24px MontserratRegular;
  letter-spacing: 0.14px;
  max-width: 604px;
  margin: 0 auto;
}
.footer-btns {
  height: 60px;
  position: fixed;
  bottom: 2px;
}

.nominees-container {
  min-width: 400px;
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, 240px);
  grid-gap: 28px;
  justify-content: center;
}
</style>
